import React from 'react'
import {NavLink} from 'react-router-dom';
import {routes} from '../routes'

function Footer() {
    return (
        <>
            <div className="footer-contact">
                <div className="wrapper">
                    <img src="img/consulting-ilu.svg" alt="Contact illustration" />
                    <div className="footer-contact-data">
                        <h2>hello@10a.io</h2>
                        <h4>Let us setup dedicated solution for you!</h4>
                    </div>
                    <div className="hr"></div>
                    <NavLink className="btn" to={routes.contact}>Contact us <i className="fa fa-caret-right"></i></NavLink>
                </div>
            </div>
            <div className="footer">
                <div className="logo-contact">
                <NavLink exact to={routes.home}><img src="img/10a-logo-white.svg" className="logo" alt="10A Logo" /></NavLink>
                    <div>
                        10A<br/>
                        Dekerta 47, Krakow, Poland
                    </div>
                </div>

                <div className="footer-menu">
                    <NavLink exact to={routes.home} className="footer-menu-link">Home</NavLink>
                    <NavLink exact to={routes.about} className="footer-menu-link">About us</NavLink>
                    <NavLink exact to={routes.career} className="footer-menu-link">Careers</NavLink>
                    <NavLink exact to={routes.wedo} className="footer-menu-link">Stuff we do</NavLink>
                    <NavLink exact to={routes.contact} className="footer-menu-link">Contact</NavLink>
                    <a target="_blank" className="footer-menu-link linkedin" href="https://pl.linkedin.com/company/softwarehouse-10a" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                </div>
            </div>
        </>
    )
}

export default Footer
