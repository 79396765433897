import React from 'react'
import Portfolio from '../components/Portfolio'
import WeAreExperts from '../components/WeAreExperts'
import JobPosition from '../components/JobPosition';
import {Slide} from 'react-slideshow-image';
import {jobsData} from '../data/jobsData';

const images = [
    {url:'img/photos/9.jpg'},
    {url:'img/photos/8.jpg'},
    {url:'img/photos/6.jpg'},
    {url:'img/photos/5.jpg'},
];

function Career() {
    
    const renderJobs = (jobs) => {
        if(!jobs) return;
        return jobs.map(({url, position}, index)=> <JobPosition key={`job-${index}`}url={url?url:""} position={position?position:""}/>);
    }

    return (
        <>
            <div className="section-photo section-photo-right">

                <div className="slider">
                    <Slide
                        arrows ={false}
                        cssClass="slider-wrapp"
                        duration={6000}
                        easing="ease"
                        transitionDuration={500}
                    >
                        {images.map((image, index) => (
                            <div className="each-slide" key={index}>
                                <div style={{'backgroundImage': `url(${image.url})`}}>
                            </div>
                        </div>
                        ))}
                    </Slide>
                </div>

                <div className="wrapper">
                    <div className="container-resp">
                        <div className="col1-2 section-photo-content">
                            <h2>Work with us!</h2>
                            <p>10A is always looking for talented humans who want to create digital products focused on other humans. We appreciate the perks of being a small and elastic organization. We’re the place where you can make a real impact on products from day one. <strong>Dogs are provided!</strong>
                                <span>Check open positions below or drop us your application at <strong>hello@10a.io</strong></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="open-positions wrapper">
                <h2>Open positions</h2>
                <div className="container-resp">
                    {renderJobs(jobsData)}
                </div>
            </div>

            <WeAreExperts />
            <Portfolio />
        </>
    )
}

export default Career
