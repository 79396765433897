import React from 'react'
import {TrustedUsData} from "../data/trustedUsData";
import {TrustedUsBreakpoints} from "../data/trustUsBreakpoints";
import 'react-slideshow-image/dist/styles.css';
import {Slide} from 'react-slideshow-image';


function TrustedBy() {

	const [items] = React.useState(TrustedUsData);
	const [breakpoints] = React.useState(TrustedUsBreakpoints);

	return (
		<div className="trusted-by wrapper">
			<h4><span>Trusted by both corporate leaders and fast-growing startups</span></h4>


				<Slide
					arrows={false}
					indicators={false}
					slidesToShow={6}
					slidesToScroll={1}
					duration={3000}
					transitionDuration={500}
					infinite={true}
					easing="ease"
					responsive = {breakpoints}
				>

					{items.map((item, index) => (
						<div key={index} className={"trusted-card-wrapper"}>
							<img style={{objectFit: "contain"}}
								 src={item.src}
								 alt={item.alt}
							/>
						</div>
					))}

				</Slide>

		</div>
	)
}

export default TrustedBy