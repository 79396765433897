import React from 'react';


const JobPosition = ({url, position}) => {
    return ( 
    <a target="_blank" href={url} className="open-position-link col1-2" rel="noopener noreferrer">
        <img src="img/development-ilu.svg" alt=""/>
        <h3>{position}</h3>
        <i className="fa fa-caret-right"></i>
    </a>);
}
 
export default JobPosition;