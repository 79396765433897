import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollHandler = ({ location, history }) => {
    useEffect(() => {
        const element = document.getElementById(location.hash.replace("#", ""));

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop : 0
            });
        }, 100);
    }, [location]);

    useEffect(() => {
        const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
        });
        return () => {
        unlisten();
        }
    }, [history]);


    return null;
};

export default withRouter(ScrollHandler);