import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { routes } from '../routes'

function WeAreExperts() {
    return (
        <div className="services-list wrapper">
            <h2>We're experts in:</h2>
            <div className="container-resp">
                <div className="col1-4">
                    <img src="img/dedicated-team-ilu.svg" alt="Dedicated Solutions Illustration" />
                    <h3>Dedicated solutions</h3>
                    <p>We create dedicated products and teams, provide a comprehensive approach starting with a concept up until the implementation of a digital product, and offer support in chosen areas.</p>
                </div>

                <div className="col1-4">
                    <img src="img/development-ilu.svg" alt="Dedicated Solutions Illustration" />
                    <h3>Development</h3>
                    <p>We create web and mobile applications. We write integrations (including hardware-based solutions), maintain existing products and provide dedicated teams of talented developers.</p>
                </div>

                <div className="col1-4">
                    <img src="img/design-ilu.svg" alt="Dedicated Solutions Illustration" />
                    <h3>Product Design</h3>
                    <p>We improve product concepts via conducting research and workshops, provide UX design and audits, as well as integrate UI components with development through Design Systems.</p>
                </div>

                <div className="col1-4">
                    <img src="img/consulting-ilu.svg" alt="Dedicated Solutions Illustration" />
                    <h3>Consulting</h3>
                    <p>We provide companies with the support in the world of digital technologies and products. We answer your questions, but also indicate those that are worth asking.</p>
                </div>
            </div>
            <div className="cta-line">
                <NavLink className="btn" to={routes.wedo}>Read more <i className="fa fa-caret-right"></i></NavLink>
            </div>
        </div>
    )
}

export default WeAreExperts
