export const portfolioData = [
    {
        id: 1,
        image: 'img/portfolio/suzuki.jpg',
        name: 'Suzuki Motor Poland',
        desc: 'Throughout many years of cooperation, we have developed a digital ecosystem consisting of dedicated products for business management and marketing of Suzuki. We created a CRM, an extensive website system for the entire sales network, an innovative mobile application for customers and service stations, and other various business tools. All connected with global Suzuki systems and stably maintained around the clock since 2014.',
        logo: 'img/portfolio/suzuki-logo.svg',
        background_color: "#0A83B7",
    },
    {
        id: 2,
        image: 'img/portfolio/chow.png',
        name: 'cHow',
        desc: 'Platform consisting of a web part and mobile applications for iOS and Android. Currently daily used by the biggest retail organizations in Poland to replace e-mails, checklists, and spreadsheets whenever they need to quickly collect and transparently present data from the field.',
        logo: 'img/portfolio/cHow-logo.svg',
        background_color: "#32415e",
    },
    {
        id: 3,
        image: 'img/portfolio/idea.png',
        name: 'IdeaBank',
        desc: 'Full-fledged online banking web application developed based on the API of the core banking system. A single-page application, developed using the Java Server Faces technology for scalability and stability, enables thousands of users to access their banking products.',
        logo: 'img/portfolio/IdeaBank-logo.svg',
        background_color: "#037aa4",
    },
];
