import React, {useState} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { routes } from '../routes/index';
import Menu from './Menu';

function Header() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const location = useLocation();

    const handleToggleMenu = () => {
        setIsMenuOpen(prevState => !prevState)
    }

    const getSubPageName = () => {
        if(!location) return null;
        const path = location.pathname;

        switch(path) {
            case routes.about:
                return 'About us'
            case routes.career:
                return 'Career'
            case routes.contact:
                return 'Contact'
            case routes.wedo:
                return 'Stuff we do'
            default:
                return null;
        }
    }

    if(location.pathname === routes.home) {
        return (
            <>
                {!isMenuOpen && <button onClick={handleToggleMenu} className="menu-btn">
                    <i className="fa fa-bars"></i>
                </button>}
                <Menu isOpen={isMenuOpen} onClose={handleToggleMenu} />
            </>
        )
    }

    return (
        <>
            <div className="subpage-header">
                <div className="page-title">
                    <NavLink to="/"><img src="img/10a-logo.svg" className="logo" alt="10A Logo" /></NavLink>
                    <h1>{getSubPageName()}</h1>
                </div>
                {!isMenuOpen && <button onClick={handleToggleMenu} className="menu-btn">
                    <i className="fa fa-bars"></i>
                </button>}
            </div>
            <Menu isOpen={isMenuOpen} onClose={handleToggleMenu} />
        </>
    )
}

export default Header
