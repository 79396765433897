export const TrustedUsBreakpoints = [
	{
		breakpoint: 1400,
		settings: {
			slidesToShow: 6,
			slidesToScroll: 1
		}
	},
	{
		breakpoint: 1024,
		settings: {
			slidesToShow: 5,
			slidesToScroll: 1
		}
	},
	{
		breakpoint: 840,
		settings: {
			slidesToShow: 4,
			slidesToScroll: 1
		}
	},
	{
		breakpoint: 640,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 1
		}
	},
	{
		breakpoint: 480,
		settings: {
			slidesToShow: 2,
			slidesToScroll: 1
		}
	},
	{
		breakpoint: 400,
		settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		}
	}
];